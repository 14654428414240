import React from "react";
import { Header } from "./header";
import { Footer } from "./footer";

export function Home() {
    return (
        <React.Fragment>
            <Header />
            <Footer />
        </React.Fragment>
    )
}