import logo from './logo.svg';
import './App.css';
import { Home } from './components/home';
import { Header } from './components/header';
import { Footer } from './components/footer';
import React from 'react';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<Home/>}/>
      {/* <Route path='/about' element={<Header/>}/>
      <Route path='/event' element={<Footer/>}/> */}
    </Routes>
    </BrowserRouter>
  );
}

export default App;
