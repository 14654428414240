import React from "react";

export function Footer() {
    return (<>
        {/* FOOTER START
                ============================================= */}
        <footer id="footer" className="footer clearfix">
          <div className="footer-wrap clearfix">
            <div className="footer-bottom clearfix">
              <div className="container">
                <div className="row">
                  <div className="foot-col column column-1 text-center clearfix">
                    <div className="logo-footer">
                      <a href="/">
                        <img src="assets/img/logo.png" alt="Logo-Footer" />
                      </a>
                    </div>
                  </div>
                  <div className="foot-col column column-1 text-center clearfix">
                    <div className="social-footer">
                      <ul>
                        <li className="soc-icon">
                          <a href="https://www.facebook.com/profile.php?id=61553977242176" target="_blank" rel="noreferrer">
                            <i className="icon icon-themify-17" />
                            Facebook
                          </a>
                        </li>
                        <li className="soc-icon">
                          <a href="https://www.instagram.com/nalina_event/?hl=en" target="_blank" rel="noreferrer">
                            <i className="icon icon-social-instagram" />
                            Instagram
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/* FOOTER END */}
      </>
      )
}