/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

const apiKey = process.env.REACT_APP_TICKET_API_KEY
export function Header() {

  const handleClickScroll = () => {
    const element = document.getElementById('next-event');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };


  const [eventSeries, setEventSeries] = useState([]);
  const [closestEvent, setClosestEvent] = useState(null);
  useEffect(() => {
    // console.log(apiKey);
    const fetchData = async () => {
      try {
        const response = await fetch('/v1/event_series', {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Basic ' + btoa(apiKey + ':')
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data);
        setEventSeries(data.data?.filter((event) => event.next_occurrence_date));
      } catch (error) {
        console.info('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const findClosestEvent = () => {
      const now = new Date().toISOString();
      let closest = null;

      for (const event of eventSeries) {
        if (event.next_occurrence_date?.iso > now && (!closest || event.next_occurrence_date?.iso < closest.next_occurrence_date?.iso)) {
          closest = event;
        }
      }

      setClosestEvent(closest);
    };

    findClosestEvent();
  }, [eventSeries]);

  const calculateTimeLeft = () => {
    if (!closestEvent || !closestEvent.next_occurrence_date) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const now = new Date().getTime();
    const targetTime = new Date(closestEvent.next_occurrence_date?.iso).getTime();

    if (isNaN(targetTime) || targetTime <= now) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const timeDifference = targetTime - now;
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timerInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closestEvent?.next_occurrence_date?.iso]);
  return (
    <>
      {/* HEADER START
          ============================================= */}
      <header id="header" className="header-style-1">

        <div className="header-clear">
          <div className="logo">
            <img src="assets/img/logo.png" alt="Nalina Logo" width="60%" />
          </div>
        </div>

      </header>
      {/* HEADER END */}
      <>
        {/* SECTION */}
        <section className="content">
          {/* MAIN VIDEO BACKGROUND */}
          <div className="main-video">
            <div className="image-background"></div>
            <div className="image-title">
              {/* <p>Nalina presents</p> */}
              <a href="#" className="button-basic-1" data-aos="fade-up" onClick={handleClickScroll}>
                Upcoming Event
              </a>
            </div>
            <div className="video-background">
              <video autoPlay loop muted id="myVideo">
                <source src="assets/img/video.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
          {/* MAIN VIDEO BACKGROUND END */}
          <div className="background-overlay" />
          <div className="about-page">
            <div className="container">
              <div className="about-wrap">
                <div className="about-img">
                  <img src="assets/img/bg-into2.png" alt="about-img" />
                </div>
                <div className="about-text">
                  <div className="the-title">
                    <h5 className="head-title-2" data-aos="fade-up">
                      {" "}
                      / STORY{" "}
                    </h5>
                    <h2 className="head-title-1">
                      {" "}
                      Brief About US
                      <span className="title-end">.</span>
                    </h2>
                  </div>
                  <div className="the-text grid grid-cols-12 gap-20">
                    <p className="col-span-6 sm:col-span-12 res:col-span-12">

                      <span className="head-title-2" style={{ fontSize: '15px' }}> 🦋 NALINA </span> is to create an enchanting haven for K-POP enthusiasts and aficionados of Asian culture in Hungary and beyond.
                      Join us for a captivating evening where you can dance freely and immerse yourself in a fantastic party atmosphere🫰
                    </p>
                    <p className="col-span-6 sm:col-span-12 res:col-span-12">
                      Become a part of the vibrant and healthy community and mark your calendar for our next spectacular event! Prepare yourself for an experience that resonates deeply, promising high-quality entertainment infused with 🔥energies.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* PAGE TITLE END */}
          {/* GALLERY BLOCK */}
          {/* <div id="lightgallery" className="about-gallery clearfix">
            <div className="gallery clearfix">
              <div className="gallery-block-wrap grid grid-cols-12 gap-10">
                <div
                  data-src="assets/img/about-gallery-1.png"
                  className="column-gallery col-span-4 sm:col-span-6 res:col-span-6"
                >
                  <div className="item-wrap">
                    <figure className="imghvr-circle-top-left">
                      <img src="assets/img/about-gallery-1.png" alt="gallery-pesta-1" />
                      <figcaption>
                        <div className="caption-inside">
                          <div className="gallery-icon">
                            <i className="fas fa-search"> </i>
                          </div>
                        </div>
                      </figcaption>
                    </figure>
                  </div>
                </div>
                <div
                  data-src="assets/img/about-gallery-1.png"
                  className="column-gallery col-span-4 sm:col-span-6 res:col-span-6"
                >
                  <div className="item-wrap">
                    <figure className="imghvr-circle-top-left">
                      <img src="assets/img/about-gallery-1.png" alt="gallery-pesta-1" />
                      <figcaption>
                        <div className="caption-inside">
                          <div className="gallery-icon">
                            <i className="fas fa-search"> </i>
                          </div>
                        </div>
                      </figcaption>
                    </figure>
                  </div>
                </div>  <div
                  data-src="assets/img/about-gallery-2.png"
                  className="column-gallery col-span-4 sm:col-span-6 res:col-span-6"
                >
                  <div className="item-wrap">
                    <figure className="imghvr-circle-top-left">
                      <img src="assets/img/about-gallery-2.png" alt="gallery-pesta-1" />
                      <figcaption>
                        <div className="caption-inside">
                          <div className="gallery-icon">
                            <i className="fas fa-search"> </i>
                          </div>
                        </div>
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* GlOBAL BLOCK */}
          {closestEvent ? (
            <div className="global-block">
              <div className="container">
                <div className="global-block-wrap grid grid-cols-12 gap-8">
                  <div className="global-img col-span-5 sm:col-span-12 res:col-span-12">
                    <div className="item-content">
                      <div className="img-header">
                        <a className="pattern" href="#">
                          <img src="/assets/img/event.jpg" alt="event-img" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="global-counter col-span-7 sm:col-span-12 res:col-span-12" id="next-event">
                    <h2 className="head-title-1">
                      {" "}
                      Next Event
                      <span className="title-end">.</span>
                    </h2>
                    <div className="global-title">
                      <a href="#" className="button-basic-1">
                        {closestEvent.timezone}
                      </a>
                      <h2 data-aos="fade-up">{closestEvent.name}</h2>
                      <h5 data-aos="fade-up">/ {closestEvent.next_occurrence_date.formatted}</h5>
                      <p className="dugem-text" data-aos="fade-up">
                        <div dangerouslySetInnerHTML={{ __html: closestEvent.description }} />
                      </p>
                    </div>
                    <div className="global-buy" data-aos="fade-up">
                      <a href={closestEvent.url} className="button-basic-2" target="_blank" rel="noopener noreferrer">
                        BUY TICKET
                      </a>
                    </div>
                    <div className="global-countdown">
                      <div className="event-counter clearfix">
                        <div className="countdown">
                          <div className="days-count float">
                            <h3>{timeLeft.days}</h3>
                            <span>Days</span>
                          </div>
                          <div className="hours-count float">
                            <h3>{timeLeft.hours}</h3>
                            <span>Hours</span>
                          </div>
                          <div className="minutes-count float">
                            <h3>{timeLeft.minutes}</h3>
                            <span>Minutes</span>
                          </div>
                          <div className="seconds-count float">
                            <h3>{timeLeft.seconds}</h3>
                            <span>Seconds</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p className="text-center">No upcoming events</p>
          )}
          {/* GlOBAL BLOCK END */}
          {/* TABLE EVENTS */}
          <div className="table-events" id="upcoming">
            <div className="background-overlay" />
            <div className="container">
              <div className="the-title text-center" data-aos="fade-up">
                <h5 className="head-title-2"> / EVENTS </h5>
                <h2 className="head-title-1">
                  {" "}
                  Upcoming Event
                  <span className="title-end">.</span>
                </h2>
                <p className="dugem-text">
                  {" "}
                  Explore our upcoming events and dive into the details by clicking on 'Buy Ticket.' Join us for an immersive experience as we unfold a series of exciting events. Secure your spot and be part of these memorable occasions. Don't miss out on the opportunity to be a part of something extraordinary
                </p>
              </div>
              <table className="event">
                <tbody>
                  <tr>
                    <td><strong>Event</strong></td>
                    <td><strong>When</strong></td>
                    <td><strong>Location</strong></td>
                    <td></td>
                  </tr>
                  <tr className="space">
                    <td>&nbsp;</td>
                  </tr>
                  {eventSeries.map((event, index) => (
                    <React.Fragment key={index}>
                      <tr className="space">
                      </tr>
                      <tr>
                        <td className="event-thumb">
                          {/* <img src={event.images.thumbnail} alt={`table-${index}`} />  */}
                          <h2 className="head-title-1">
                            {event.name}
                            <span className="title-end">.</span>
                          </h2>
                        </td>
                        <td className="event-date">{event.next_occurrence_date?.date}</td>
                        <td className="event-location">
                          {event.venue.name}
                        </td>
                        <td className="event-ticket-link">
                          <a href={event.url} className="button button-white" target="_blank" rel="noopener noreferrer">
                            BUY TICKET
                          </a>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>

              {/* Mobile View */}
              <div className="event-mobile">
                <ul className="event-list">
                  {eventSeries.map((event, index) => (
                    <li key={index}>
                      <span className="img-thumb">
                        <a href={event.url} target="_blank" rel="noopener noreferrer">
                          <img width={70} height={100} src={event.images.thumbnail} alt={`table-1-${index}`} />
                        </a>
                      </span>
                      <p>
                        <span>Event :</span> <a href="#">{event.name}</a>
                      </p>
                      <p>
                        <span>When :</span> <a href="#">{event.next_occurrence_date?.date}</a>
                      </p>
                      <p>
                        <span>Location :</span> <a href="#"> {event.venue.name}</a>
                      </p>
                      <p>
                        <a href={event.url} className="button-basic-1" target="_blank" rel="noopener noreferrer">
                          BUY TICKET
                        </a>
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
              {/* <div className="button-view text-center">
          <a href="event.html" className="button-basic-1" data-aos="fade-up">
            VIEW ALL EVENT
          </a>
        </div> */}
            </div>
          </div>
          {/* TABLE EVENTS END */}
          {/* BLOG LOOP */}
          <div className="blog-loop">
            <div className="container">
              <div className="news-title grid grid-cols-12">
                <div
                  className="the-title col-span-6 sm:col-span-12 res:col-span-12"
                  data-aos="fade-up"
                >
                  <h5 className="head-title-2"> / Gallery </h5>
                  <h2 className="head-title-1">
                  </h2>
                  <p className="dugem-text">
                    {" "}
                    Follow our instagram
                  </p>
                </div>
                <div
                  className="button-view col-span-6 sm:col-span-12 res:col-span-12"
                  data-aos="fade-up"
                >
                </div>
              </div>
            </div>
          </div>
          {/* BLOG LOOP END */}
        </section>
        {/* SECTION END */}
      </>

    </>

  )
}